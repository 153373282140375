import { LimitedFeedPagination } from "layout/homepage/feeds/limited-pagination/limited-pagination";
import { useCategoryInfo } from "cms/hooks/use-category-info";
import { useFetchFeedCustom } from "hooks/use-fetch-feed-custom";
import { useWindowSize } from "layout/homepage/useWindowSize";
import { isArrayNotEmpty } from "cms/utils/empty-exists";
import { NewsFeed } from "layout/homepage/feeds/feed-carousel/news/news-feed";

const NewsFeedCarousel = () => {
    const type = "model.news";
    const { categoryName } = useCategoryInfo(type);
    const { isDesktop, isTablet, currentSize } = useWindowSize();
    const selected_category = "All";
    const sizes = {
        small: 6,
        medium: 2,
        large: 3,
    };
    const limits = {
        small: 1,
        medium: 3,
        large: 2,
    };
    const pageSize = sizes[currentSize];
    const pageLimit = limits[currentSize];
    const links = [{ text: "All news", path: "/news" }];
    const header = "Latest news";

    const { loading, value, pagination } = useFetchFeedCustom(
        type,
        categoryName,
        selected_category,
        pageSize,
        pageLimit
    );

    let { models } = value || {};

    return (
        <>
            <div className={isDesktop || isTablet ? "feed-latest-news two-third" : "feed-latest-news"}>
                <div className="template-wrap">
                    <div className="feed-component-header-clear">
                        <div className="feed-component-title-wrapper">
                            <h2 className="feed-component-title-link">{header}</h2>
                            <div className="feed-component-title-underline"></div>
                        </div>
                        <div className="feed-component-links-wrapper">
                            {/* All items link */}
                            <div className="feed-component-all-links">
                                {isArrayNotEmpty(links) &&
                                    links.map((link, index) => {
                                        return (
                                            <a key={index} href={link.path}>
                                                {link.text}
                                            </a>
                                        );
                                    })}
                            </div>
                            {/* Pagination arrows */}
                            <LimitedFeedPagination pagination={pagination} pageLimit={pageLimit} />
                        </div>
                    </div>
                    <div className="result-item-row">
                        <div className="column">
                            <NewsFeed models={models} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export { NewsFeedCarousel };
