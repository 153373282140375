import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CarouselImage } from 'cms/components/hero-carousel/carousel-image'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'

const HeroCarouselSlideHomepage = (props) => {
    
    const { slides, images, index, total, moveIndex } = props || {}
    const slide = slides[index]
    const { title, description, link } = slide || {}
    const { text, url, link_type } = link || {}
    const image = Array.isArray(images) && images[index]
    const settings = slide?.settings
    
    return (
        <Fragment>
            <div className="home-banner">
                <div className="home-banner-content">
                    <h1>{title}</h1>
                    
                    {description &&
                        <div className='hero-description-wrapper'>
                            <RichTextSimple
                                richtext={description}
                                className="carousel-slide-description"
                            />
                        </div>
                    }
                    
                    <div className="home-banner-links">
                        
                        <Link to={url}>{text}</Link>
                        
                        <div className="controls">
                            <div className="forward-back">
                                <button aria-label="Previous" onClick={() => moveIndex(-1)}>
                                    <FontAwesomeIcon icon={`fa-regular fa-chevron-left`} />
                                </button>
                                
                                <span>{`${index + 1}/${total}`}</span>
                                
                                <button aria-label="Next" onClick={() => moveIndex(1)}>
                                    <FontAwesomeIcon icon={`fa-regular fa-chevron-right`} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <CarouselImage
                    image={image}
                    slide={slide}
                    settings={settings}
                    isHomepage={true}
                />
                
            </div>
        </Fragment>
    )
}

HeroCarouselSlideHomepage.displayName = 'HeroCarouselSlideHomepage'
export { HeroCarouselSlideHomepage }
