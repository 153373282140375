import React from "react";
import { BannerImage } from "cms/shared/images/banner-image";
import { Link } from "react-router-dom";

const StoryResultItem = (props) => {
    const { model } = props;
    const { title, path, description } = model || {};

    return (
        <div className="simplicity-story-item">
            {/*  Banner Image  */}
            <div className="views-field views-field-field-banner-image">
                <div className="field-content banner-image-style">
                    <BannerImage model={model} />
                </div>
            </div>

            <div className="views-field views-field-content">
                {/*  Title / Link  */}
                <div className="simplicity-story-item-title">
                    <h2 className="field-content">
                        <Link to={path}>{title}</Link>
                    </h2>
                </div>

                {/*  Story Summary  */}
                <div className="views-field views-field-body">
                    <div className="field-content">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { StoryResultItem };
