import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from 'icons'
import { useRef, useState } from 'react'
import {
    useFloating,
    useDismiss,
    useHover,
    safePolygon,
    useInteractions,
    offset,
    FloatingPortal,
    FloatingArrow,
    arrow,
    flip, useClick
} from '@floating-ui/react'
const { faCircle } = solid
import { useNavigate, Link } from 'react-router-dom'

const EventContent = (props) => {
    
    const { event } = props
    const [openEventView, setOpenEventView] = useState(false)
    const navigate = useNavigate()
    const arrowRef = useRef(null)
    const { refs, floatingStyles, context } = useFloating({
        open: openEventView,
        onOpenChange: setOpenEventView,
        middleware: [
            offset(10),
            arrow({
                element: arrowRef,
            }),
            flip({
                mainAxis: true,
                crossAxis: true,
            }),
        ],
    })
    const hover = useHover(context, {
        delay: {
            // open: 500,
            close: 1200,
        },
        handleClose: safePolygon(),
    })
    // const dismiss = useDismiss(context)
    // const click = useClick(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([hover])
    
    const start = dayjs(event.event.start).format('HH:mm')
    const ampm = dayjs(event.event.start).format('a').slice(0,1)
    const title = !!event ? event.event.title : ''
    const url = !!event ? event.event.url : undefined
    
    const handleClick = (info) => {
        const url = info.event.url
        alert(url)
        navigate(url)
    }
    
    return (
        <>
            <div
                ref={refs.setReference}
                {...getReferenceProps()}
                className='calendar-event-wrapper'
            >
                <FontAwesomeIcon
                    icon={faCircle}
                    className='event-icon'
                /> {start}{ampm} <span
                className='event-title'>{title}</span>
            </div>
            {openEventView &&
                <FloatingPortal>
                    <div
                        onClick={handleClick}
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        className='event-panel'
                        style={{ ...floatingStyles }}>
                        <div style={{ fontWeight: 700 }}>
                            <Link to={url}>{title}</Link>
                        </div>
                        <div>{start}{ampm}</div>
                        <FloatingArrow ref={arrowRef} context={context} fill={'#999999'} />
                    </div>
                </FloatingPortal>
            }
        </>
    )
}
export { EventContent }
