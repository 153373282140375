
// named "selected" regular icons in the kit
import {
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowToBottom,
    faArrowUpRightFromSquare,
    faBars,
    faBasketballHoop,
    faBriefcase,
    faBuildingColumns,
    faBus,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faCircle,
    faCircleDot,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClock,
    faCloudArrowUp,
    faComment,
    faComments,
    faDiamondExclamation,
    faDownload,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faHand,
    faHouseChimneyWindow,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faPeopleLine,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRoadBarrier,
    faScaleBalanced,
    faShield,
    faSortDown,
    faSortUp,
    faShareNodes,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faSuitcase,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
// } from '@awesome.me/kit-f6a7b16729/icons/classic/regular'
} from '@awesome.me/kit-5f107d6f8b/icons/classic/regular'


const regular = {
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowToBottom,
    faArrowUpRightFromSquare,
    faBars,
    faBasketballHoop,
    faBriefcase,
    faBuildingColumns,
    faBus,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleDot,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClock,
    faCloudArrowUp,
    faComment,
    faComments,
    faDiamondExclamation,
    faDownload,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faHand,
    faHouseChimneyWindow,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faPeopleLine,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRoadBarrier,
    faScaleBalanced,
    faShareNodes,
    faShield,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faSuitcase,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
}

export { regular }
