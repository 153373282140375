/**
 *  Options for logger
 */
export const logOptions = {
    // Default sync endpoint (protocol + domain)
    // endpoint: "https://in.logs.betterstack.com",
    
    // Maximum number of logs to sync in a single request to Better Stack
    batchSize: 1000,
    
    // Size of logs (in KiB) to trigger sync to Better Stack (0 to disable)
    batchSizeKiB: 0,
    
    // Max interval (in milliseconds) before a batch of logs proceeds to syncing
    batchInterval: 1000,
    
    // Maximum number of times to retry a failed sync request
    retryCount: 3,
    
    // Minimum number of milliseconds to wait before retrying a failed sync request
    retryBackoff: 100,
    
    // Maximum number of sync requests to make concurrently
    syncMax: 5,
    
    // Length of the checked window for logs burst protection in milliseconds (0 to disable)
    burstProtectionMilliseconds: 5000,
    
    // Maximum number of accepted logs in the specified time window (0 to disable)
    burstProtectionMax: 10000,
    
    // If true, errors when sending logs will be ignored
    // Has precedence over throwExceptions
    ignoreExceptions: false,
    
    // If true, errors when sending logs will result in a thrown exception
    throwExceptions: false,
    
    // Maximum depth (number of attribute levels) of a context object
    contextObjectMaxDepth: 50,
    
    // Produce a warn log when context object max depth is reached
    contextObjectMaxDepthWarn: true,
    
    // Produce a warning when circular reference is found in context object
    contextObjectCircularRefWarn: true,
    
    // If true, all logs will be sent to standard console output functions (console.info, console.warn, ...)
    sendLogsToConsoleOutput: false,
    
    // If true, all logs will be sent to Better Stack
    sendLogsToBetterStack: true,
    
    // Function to be used to calculate size of logs in bytes (to evaluate batchSizeLimitKiB)
    // calculateLogSizeBytes: (log) => JSON.stringify(log).length + 1,
}

export const colors = {
    black: "30",
    red: "31",
    green: "32",
    yellow: "33",
    blue: "34",
    magenta: "35",
    cyan: "36",
    white: "37",
    gray: "90",
    grey: "90",
    blackBright: "90",
    redBright: "91",
    greenBright: "92",
    yellowBright: "93",
    blueBright: "94",
    magentaBright: "95",
    cyanBright: "96",
    whiteBright: "97"
}
