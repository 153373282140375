import { QuickSearch } from "components/search";
import { WebsiteLogo } from "components/header/logo";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from 'icons'
const {
    faMessage,
    faTriangleExclamation
} = solid

const TopBar = () => {
    const isDesktop = useMedia("screen and (min-width: 1125px)");
    const isTablet = useMedia("screen and (max-width: 1060px)");
    const isMobile = useMedia("screen and (max-width: 480px)");
    return (
        <div className="top-bar">
            {isDesktop && (
                <div className="top-bar-logo">
                    <WebsiteLogo />
                </div>
            )}
            <div className="top-bar-content">
                <ul>
                    <li>
                        <Link to="/government-administration/report-problem" title="Report a Problem">
                            {isTablet && !isMobile &&
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                />
                            }
                            {(!isTablet || isMobile) && "Report a Problem"}
                        </Link>
                    </li>
                    <li>
                        <Link to="/government-administration/contact-us" title="Contact Us">
                            {isTablet &&
                                <FontAwesomeIcon
                                    icon={faMessage}
                                />
                            }
                            {!isTablet && "Contact Us"}
                        </Link>
                    </li>
                </ul>

                <QuickSearch />
            </div>
        </div>
    );
};

export { TopBar };
