import { useState, useEffect } from "react";

const useWindowSize = () => {
    
    const SIZES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    }
    const limit_sm = 1061
    const limit_md = 1199
    const min_lg = 1200
    
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= min_lg);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= limit_md && window.innerWidth >= limit_sm);
    const [isPhone, setIsPhone] = useState(window.innerWidth < limit_sm);
    const [currentSize, setCurrentSize] = useState(SIZES.LARGE);
    
    const determineSize = () => {
        const width = window.innerWidth
        const height =  window.innerHeight
        setWindowSize({width, height});
        const isLarge = width >= min_lg;
        setIsDesktop(isLarge);
        const isMedium = width <= limit_md && width >= limit_sm;
        setIsTablet(isMedium);
        const isSmall = width < limit_sm;
        setIsPhone(isSmall);
        if (isLarge) {
            setCurrentSize(SIZES.LARGE)
        } else if (isMedium) {
            setCurrentSize(SIZES.MEDIUM)
        } else if (isSmall) {
            setCurrentSize(SIZES.SMALL)
        } else {
            setCurrentSize(SIZES.LARGE)
        }
    }
    useEffect(() => {
        determineSize()
    }, []);
    
    useEffect(() => {
        const handleResize = () => {
            determineSize()
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    return {
        windowSize,
        width: windowSize.width,
        height: windowSize.height,
        isDesktop,
        isTablet,
        isPhone,
        SIZES,
        currentSize,
    };
};

export { useWindowSize };
