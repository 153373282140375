import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync, useCounter } from 'react-use'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { Logger } from 'cms/utils/logger'
import { isStringNotEmpty } from 'cms/utils/empty-exists'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
dayjs.extend(utc)

const defaultPage = 1
const defaultPageSize = 10
const defaultPageSizeCalendar = 500
const defaultDays = 365*2
const defaultDaysCalendar = 365*2
const modeCalendar = 'calendar'
const modeList = 'list'

/**
 * @param selectedType
 * @param {string} categoryName
 * @param selectedCategory
 * @param {boolean} isCalender
 */
const useFetchEventFeed = (selectedType, categoryName, selectedCategory, isCalender) => {
    
    const { token } = useAuth()
    let selected_type
    if (selectedType !== 'all') {
        selected_type = selectedType
    }
    
    const [term, setTerm] = useState('')
    const [category, setCategory] = useState(selectedCategory ?? '')
    useEffect(() => {
        setCategory(selectedCategory)
    }, [selectedCategory])
    
    // const defaultPage = isCalender ? defaultPageCalendar : defaultPageList
    const [page, { inc, dec, reset, set }] = useCounter(defaultPage, null, 1)
    const pageSize = isCalender
        ? defaultPageSizeCalendar
        : defaultPageSize
    const days = isCalender
        ? defaultDaysCalendar
        : defaultDays
    const mode = isCalender
        ? modeCalendar
        : modeList
    
    const { loading, error, value } = useAsync(async () => {
        
        const options = { page, pageSize, term, days, mode, type: selected_type }
        
        if (category) {
            if (category !== '- Any -' && category !== 'All' ) {
                options.category = category
                options.categoryName = categoryName
            }
        }
        const { pagination, results } = await SimpliCitySDK.model.findEventFeed(token, options)
        const models = []
        for (const model of results) {
            // const { title, path, guid, description, type } = model
            const { title, path, guid, description } = model
            const { event } = model
            Logger.debug('event',model)
            // const color = type === 'model.event' ? '#1b8898' : '#990000'
            
            const modelEvent = {
                Id: guid,
                Subject: title || 'Untitled',
                Description: description || '',
                // Location: location || '',
                StartTime: convertStartDate(event),
                // EndTime: convertEndDate(event),
                EndTime: convertStartDate(event),
                // CategoryColor: color,
                Path: path,
            }
            models.push(modelEvent)
        }
        
        return {
            models,
            pagination
        }
    }, [token, page, categoryName, term, category, isCalender])

    const search = useCallback(
        /**
         * @param {string} term 
         * @param {string} category 
         */
        (term, category) => {
            setTerm(term)
            setCategory(category)
            reset()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    const paginationState = useMemo(() => {
        const total = value?.pagination?.total || 0
        const numPages = Math.ceil(total / pageSize)
        const hasPrev = page > 1
        const hasNext = page < numPages

        const first = ((page - 1) * pageSize) + 1
        const last = Math.min((page) * pageSize, total)
        const hasResults = total > 0

        return {
            first,
            hasNext,
            hasPrev,
            hasResults,
            last,
            numPages,
            page,
            pageSize,
            total,
        }
    }, [page, pageSize, value?.pagination?.total])

    const controls = {
        next: () => inc(),
        prev: () => dec(),
        to: set,
    }

    return {
        // state
        loading,
        error,
        value,

        // pagination
        pagination: {
            controls,
            state: paginationState
        },
        search,
    }
}
const convertStartDate = (event) => {
    let start
    const hasDate = isStringNotEmpty(event.StartDate)
    const hasTime = isStringNotEmpty(event.StartTime)
    if (hasDate && hasTime) {
        start = `${event.StartDate} ${event.StartTime}`
    } else if (hasDate) {
        start = `${event.StartDate}`
    } else if (hasTime) {
        start = `${event.StartTime}`
    }
    const date = dayjs.utc(start).toDate()
    return date
}

// const convertEndDate = (event) => {
//     let end, date
//     const hasDate = isStringNotEmpty(event.EndDate)
//     const hasTime = isStringNotEmpty(event.EndTime)
//     if (hasDate && hasTime) {
//         end = `${event.EndDate} ${event.EndTime}`
//         date = dayjs.utc(end).toDate()
//     } else if (hasDate) {
//         end = `${event.EndDate}`
//         date = dayjs(end).toDate()
//     } else if (hasTime) {
//         end = `${event.EndTime}`
//         date = dayjs.utc(end).toDate()
//     }
//     return date
// }

// const getFieldValue = (field) => {
//     if (!field) {
//         return
//     }
//     const type = field?.type
//     const {values} = field
//     let value, date
//     switch (type) {
//         case 'field.date':
//             value = values[0]?.value?.date
//             // date = value ? new Date(value) : undefined
//             date = parseDate(value)
//             return date
//         case 'field.text':
//             value = values[0].value
//             return value
//         default:
//             value = values ? values[0].value : undefined
//             return value
//     }
// }
// const parseDate = (value) => {
//     const result = value ? dayjs.parse(value, 'YYYY-MM-DD') : ''
//     return result
// }


export { useFetchEventFeed }
