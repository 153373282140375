import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from 'icons'
import { useRef, useState } from 'react'
import {
    arrow,
    flip,
    FloatingArrow,
    offset,
    useFloating,
    useHover,
    useInteractions
} from '@floating-ui/react'
import dayjs from 'dayjs'
import { fontWeight } from "@mui/system";
const { faCircle } = solid

const AllDayContent = (props) => {
    
    const { event} = props
    const [openEventView, setOpenEventView] = useState(false)
    const arrowRef = useRef(null)
    const { refs, floatingStyles, context } = useFloating({
        open: openEventView,
        onOpenChange: setOpenEventView,
        middleware: [
            offset(10),
            arrow({
                element: arrowRef,
            }),
            flip({
                mainAxis: true,
                crossAxis: true,
            }),
        ],
    })
    
    const hover = useHover(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([hover])
    const start = !!event ? dayjs(event.event.start).format('HH:mm') : ''
    const ampm = !!event ? dayjs(event.event.start).format('a').slice(0,1) : ''
    const title = !!event ? event.event.title : ''
    
    return (
        <>
            <div
                ref={refs.setReference}
                {...getReferenceProps()}
                className='calendar-allday-event-wrapper'
  
            >
                <FontAwesomeIcon
                    icon={faCircle}
                    className='event-all-day-icon'
                /> <span className='allday-event-title'>{title}</span>
            </div>
            {openEventView &&
                <div
                    ref={refs.setFloating}
                    {...getFloatingProps()}
                    className='all-day-event-panel'
                    style={{ ...floatingStyles }}>
                    <div style={{fontWeight: 700}}>{title}</div>
                    <div>{start}{ampm}</div>
                    <FloatingArrow ref={arrowRef} context={context} fill={'#999999'} />
                </div>
            }
        </>
    )
}

export { AllDayContent }
