import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { AllDayContent } from './all-day-event-content'
import { EventContent } from './event-content'
import { useNavigate } from 'react-router-dom'

const transform = (rawEvents) => {
    const events = []
    rawEvents.forEach((event) => {
        const _event = {
            id: event.Id,
            title: event.Subject,
            start: event.StartTime,
            end: event.EndTime,
            allDay: false,
            url: event.Path
        }
        events.push(_event)
    })
    return events
}


const EventCalendar = (props) => {
    
    const { events: rawEvents } = props
    const navigate = useNavigate()
    const events = isArrayNotEmpty(rawEvents) ? transform(rawEvents) : []
    const hasEvents = isArrayNotEmpty(events)

    const getEventContent = (event) => {
        return <EventContent event={event} />
    }
    
    const getAllDayEventContent = (event) => {
        return <AllDayContent event={event} />
    }
    
    const onEventClick = (info) => {
        const url = info.event.url
        navigate(url)
    }
    
    return (
        <>
            {hasEvents &&
                <div
                    className='calendar-wrapper'
                >
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView='dayGridMonth'
                        weekends={true}
                        events={events}
                        eventDisplay={'block'}
                        eventClick={onEventClick}
                        eventContent={getEventContent}
                        allDayContent={getAllDayEventContent}
                    />
                </div>
            }
        </>
    )
}

export { EventCalendar }
