import { LimitedFeedPagination } from "layout/homepage/feeds/limited-pagination/limited-pagination";
import { useCategoryInfo } from "cms/hooks/use-category-info";
import { useFetchFeedCustom } from "hooks/use-fetch-feed-custom";
import { isArrayNotEmpty } from "cms/utils/empty-exists";
import { StoriesFeed } from "layout/homepage/feeds/feed-carousel/story/stories-feed";
import { useWindowSize } from "layout/homepage/useWindowSize";

const StoryFeedCarousel = () => {
    
    const type = "model.story"
    const { categoryName } = useCategoryInfo(type);
    const { currentSize } = useWindowSize();
    const selected_category = "All";
    // const pageSize = 2
    // const pageLimit = 2
    const sizes = {
        small: 4,
        medium: 2,
        large: 2,
    }
    const limits = {
        small: 1,
        medium: 2,
        large: 2,
    }
    const pageSize = sizes[currentSize];
    const pageLimit = limits[currentSize];
    const links = [{text: "All stories", path: "/dnv-stories"}]
    const header = "DNV stories"
    
    const { loading, value, pagination } = useFetchFeedCustom(
        type,
        categoryName,
        selected_category,
        pageSize,
        pageLimit
    );

    let { models } = value || {};
    
    return (
        <>
            <div id="feed-home-row-3" className="feed-home-row stories">
                <div className="template-wrap">
                    <div className="feed-component-header-clear">
                        <div className="feed-component-title-wrapper">
                            <h2 className="feed-component-title-link">{header}</h2>
                            <div className="feed-component-title-underline"></div>
                        </div>
                        <div className="feed-component-links-wrapper">
                            
                            {/* All items link */}
                            <div className="feed-component-all-links">
                                {isArrayNotEmpty(links) && links.map((link, index) => {
                                    return <a key={index} href={link.path}>{link.text}</a>
                                })}
                            </div>
                            {/* Pagination arrows */}
                            <LimitedFeedPagination
                                pagination={pagination}
                                pageLimit={pageLimit}
                            />
                        </div>
                    </div>
                    <div className="result-item-row">
                        <div className="column">
                            
                            <StoriesFeed models={models} />
                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export { StoryFeedCarousel };
