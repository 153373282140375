import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, regular } from "icons";

function FooterSocial() {
    return (
        <div className="footer-social">
            <h2>Follow us</h2>
            <div className="social-links">
                <a href="https://twitter.com/nvandistrict" aria-label="Folow us on Twitter">
                    <FontAwesomeIcon icon={brands.faXTwitter} size={"2x"} /> <span className="link-text">X</span>
                    <span className="screen-reader">Follow us on Twitter</span>
                </a>
                <a href="https://www.youtube.com/user/NorthVanDistrict" aria-label="Folow us on YouTube">
                    <FontAwesomeIcon icon={brands.faYoutube} size={"2x"} /> <span className="link-text">YouTube</span>
                    <span className="screen-reader">Follow us on YouTube</span>
                </a>
                <a href="https://www.facebook.com/NVanDistrict" aria-label="Folow us on Facebook">
                    <FontAwesomeIcon icon={brands.faFacebookF} size={"2x"} />{" "}
                    <span className="link-text">Facebook</span>
                    <span className="screen-reader">Follow us on Facebook</span>
                </a>
                <br />
                <a href="https://www.instagram.com/nvandistrict/" aria-label="Folow us on Instagram">
                    <FontAwesomeIcon icon={brands.faInstagram} size={"2x"} />{" "}
                    <span className="link-text">Instagram</span>
                    <span className="screen-reader">Follow us on Instagram</span>
                </a>
                <a
                    href="https://www.linkedin.com/company/district-of-north-vancouver/"
                    aria-label="Folow us on LinkedIn"
                >
                    <FontAwesomeIcon icon={brands.faLinkedinIn} size={"2x"} />{" "}
                    <span className="link-text">LinkedIn</span>
                    <span className="screen-reader">Follow us on LinkedIn</span>
                </a>
            </div>
            <a
                href="/contact-us/connect-through-social-media"
                className="more-link footer-button-link"
                aria-label="Social directory"
            >
                <div>Social directory</div>
                <div>
                    <FontAwesomeIcon icon={regular.faArrowRight} />
                </div>
            </a>
        </div>
    );
}

export { FooterSocial };
